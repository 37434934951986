import { links as productTour } from './product-tour'
import { links as securityBadge } from './security-certificate-badge'
import { links as g2badges } from './g2-badges'
import { links as tradeOffsCircles } from './trade-offs-circles'
import { links as footerDemoSection } from './footer-demo-section'

export { ProductTour } from './product-tour'
export { SecurityCertificateBadge } from './security-certificate-badge'
export { G2Badges } from './g2-badges'
export { TradeOffsCircles } from './trade-offs-circles'
export { FooterDemoSection } from './footer-demo-section'

export const fragmentLinks = {
  g2badges,
  productTour,
  securityBadge,
  tradeOffsCircles,
  footerDemoSection,
}
